import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatProgressSpinnerModule } from '@angular/material';
// import { ConfirmationDialogComponent } from './shared/modules/confirmation-dialog/confirmation-dialog.component';

import { SocialLoginModule, AuthServiceConfig,LoginOpt} from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import {SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
const data: SocketIoConfig ={ url : 'https://belive.multitvsolution.com:8030', options: {} };

const fbLoginOptions: LoginOpt = {
    scope: 'email,user_photos',
    return_scopes: true,
    enable_profile_selector: true
  }; 
  let config = new AuthServiceConfig([
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider("122231164888409",fbLoginOptions)
    }
  ]);
  
  export function provideConfig(){
    return config;
  }
@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        MatProgressSpinnerModule,
        SocialLoginModule,
        SocketIoModule.forRoot(data),

    ],
    declarations: [AppComponent, 
        // ConfirmationDialogComponent
    ],
    bootstrap: [AppComponent],
    providers: [
        {
          provide: AuthServiceConfig,
          useFactory: provideConfig
        }
    ],
})
export class AppModule {}
