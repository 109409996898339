import { Injectable } from '@angular/core';
import { CanActivate,Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { FetchDataService } from '../services/fetch-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  modules = [];
  constructor(private authServ: FetchDataService,
    private router: Router){
      this.modules = JSON.parse(localStorage.getItem('result'))[0]['modules'];
      // console.log('hello', this.modules)
    }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if(localStorage.getItem('result')){
      return true;
    }else{
      this.router.navigate(['/login'])
      return false
    }
  }
}